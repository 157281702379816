import React, { FC } from "react";
import { PageProps } from "gatsby";

// import { ContactForm } from "containers";
import { Section, Text } from "components";
import { FormLayout, FormLayoutProps } from "layouts";
import { useContactFormQuery } from "graphql";

const PageContact: FC<PageProps> = ({ location }) => {
  const { description, name, title } = useContactFormQuery();
  const page = `page-${name}`;

  const layoutProps: FormLayoutProps = {
    on: page,
    seo: {
      description,
      title,
      openGraph: {
        title,
        description,
        url: location?.href,
        images: [
          {
            url: `${location?.origin}/logo.png`,
            width: 800,
            height: 800,
            alt: title,
          },
        ],
      },
    },
  };

  return (
    <FormLayout {...layoutProps}>
      {/* <ContactForm on={page} /> */}
      <Section pattern="form" className="bg-transparent">
        <Text as="h4" pattern="title" className="mb-4">
          Are you a new client?
        </Text>
        <Text as="p" pattern="subtitle">
          It was a difficult decision to make, but in order to service our existing clients to the best of our ability we had to temporarily pause accepting new clients at this time. But, you'll be happy to know - we're working hard to provide you a new contact form experience, so very soon, you'll be able to contact us about your future project. Thanks for your patience and understanding.
        </Text>
      </Section>
    </FormLayout>
  );
};

export default PageContact;
